.home-page {
	background-color: var(--clr-dark);
	left: 0;
	top: 0;
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 1;
}

.intro-header {
	margin: 0;
	color: white;
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
	font-size: 4rem;
	line-height: 59px;
	z-index: 1;
}

.intro-header__bold {
	font-weight: 600;
	color: var(--clr-red);
}

.home-button {
	z-index: 1;
	font-size: 1.5rem;
	padding: 0.3rem 1.2rem;
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	border: 3px solid var(--clr-red);
	color: var(--clr-red);
	transition: all 300ms ease;
	font-weight: 800;
}

.svg-arrow {
	margin-left: 0.8rem;
	fill: var(--clr-red);
	transition: all 300ms ease;
}

.home-button:hover {
	cursor: pointer;
	background-color: var(--clr-red);
	color: white;
}

.home-button:hover > .svg-arrow {
	fill: white;
}

@media only screen and (max-width: 1250px) {
	.intro-header {
		font-size: 3rem;
	}

	.home-button {
		font-size: 1.2rem;
	}
}

@media only screen and (max-width: 800px) {
	.intro-header {
		font-size: 2.5rem;
	}
}

@media only screen and (max-width: 600px) {
	.intro-header {
		font-size: 2rem;
	}

	.home-button {
		font-size: 1rem;
	}
}

@media only screen and (max-width: 475px) {
	.intro-header {
		font-size: 1.5rem;
		line-height: 1.7rem;
	}

	.home-button {
		font-size: 0.8rem;
		margin-top: 1rem;
		padding: 0.3rem;
		background-color: var(--clr-red);
		color: white;
	}

	.svg-arrow {
		margin-left: 0.3rem;
		height: 1rem;
		fill: white;
	}
}
