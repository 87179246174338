@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

:root {
	--clr-dark: #2f2f2f;
	--clr-red: #ea0f68;
	--clr-green: #04c2c9;
}

* {
	margin: 0;
	padding: 0;
	scrollbar-color: grey var(--clr-dark);
	font-family: Montserrat, "sans-seriff";
	box-sizing: border-box;
}

*::-webkit-scrollbar-track {
	background: var(--clr-dark);
}

*::-webkit-scrollbar-thumb {
	background-color: grey;
}

body {
	width: 100%;
}

.app {
	position: relative;
	width: 100%;
}
