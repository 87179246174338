.navbar {
	display: flex;
	justify-content: flex-end;
	background-color: var(--clr-dark);
	position: sticky;
	left: 0;
	top: 0;
	border-bottom: 4px solid var(--clr-green);
	z-index: 10;
}

.navbar-list {
	list-style: none;
	display: flex;
}

.navbar-list__item {
	padding: 1rem;
	font-size: 1.2rem;
	color: white;
	font-family: "Montserrat", sans-serif;
}

.navbar-list__item:hover {
	cursor: pointer;
}

.list-last {
	margin-right: 10rem;
}

.nav-anchor {
	text-decoration: none;
	color: white;
}

.selected {
	color: var(--clr-red);
}

.navbar-list__mobile {
	display: none;
}

.sidebar-hamburger {
	height: 26px;
	width: 32px;
	top: 13px;
	left: 12px;
	z-index: 1;
}

.sidebar-hamburger:hover {
	cursor: pointer;
}

.sidebar-hamburger,
.line {
	display: block;
	height: 5px;
	width: 35px;
	border-radius: 10px;
	z-index: 2;
	display: none;
}

.line {
	margin: 0.4rem;
	background: white;
}

.sidebar-hamburger,
.line1 {
	transform-origin: 0% 0%;
	transition: transform 0.4s ease-in-out;
}

.sidebar-hamburger,
.line2 {
	transition: transform 0.2s ease-in-out;
}

.sidebar-hamburger,
.line3 {
	transform-origin: 0% 100%;
	transition: transform 0.4s ease-in-out;
}

.line1-clicked {
	transform: rotate(45deg);
}

.line2-clicked {
	transform: scaleY(0);
}

.line3-clicked {
	transform: rotate(-45deg);
}

@media only screen and (max-width: 1400px) {
	.list-last {
		margin-right: 5rem;
	}
}

@media only screen and (max-width: 1250px) {
	.list-last {
		margin-right: 3rem;
	}
}

@media only screen and (max-width: 900px) {
	.list-last {
		margin-right: 3rem;
	}
}

@media only screen and (max-width: 700px) {
	.navbar-list__item {
		font-size: 1rem;
		box-sizing: border-box;
	}
	.navbar-list {
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		width: 100%;
	}
	.navbar {
		display: flex;
		justify-content: center !important;
		box-sizing: border-box;
	}
	.list-last {
		margin-right: 0rem;
	}
}

@media only screen and (max-width: 385px) {
	.navbar-list__item {
		font-size: 0.8rem;
	}
}
