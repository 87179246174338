.about-page {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
}

.wave-container {
	/* position: absolute;
	top: 225%;
	left: 0; */
	width: 100%;
	overflow: hidden;
	line-height: 0;
}

.wave-container svg {
	position: relative;
	display: block;
	width: calc(128% + 1.3px);
	height: 120px;
}

.wave-container .wave {
	fill: #2f2f2f;
}

.about-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 2.5rem;
	padding: 0.7rem;
	padding-top: 5rem;
	padding-bottom: 0.3rem;
	border-bottom: 5px solid var(--clr-red);
	margin-bottom: 1.2rem;
}

.header-border-bottom {
	background-color: var(--clr-red);
	width: 6.5rem;
	height: 0.5rem;
	margin-top: 0.3rem;
	border-radius: 10px;
}

.about-content {
	display: flex;
	justify-content: center;
}

.about-content > p {
	max-width: 40%;
	padding: 1rem;
	font-family: "Montserrat", sans-serif;
	font-size: 1.2rem;
	line-height: 1.8rem;
}

.tech-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 2.5rem;
	padding: 0.7rem;
	padding-bottom: 0.5rem;
	margin-top: 3rem;
	border-bottom: 5px solid var(--clr-red);
}

.tech-header-border-bottom {
	background-color: var(--clr-red);
	width: 17rem;
	height: 0.5rem;
	margin-top: 0.3rem;
	border-radius: 10px;
	margin-right: 1rem;
}

.tool-container {
	display: flex;
	height: 25rem;
	justify-content: center;
	margin-top: 1.5rem;
	margin-bottom: 3rem;
}

.tool {
	background-color: var(--clr-dark);
	height: 100%;
	min-width: 25%;
	box-sizing: border-box;
	padding: 1rem;
	margin: 0 2rem;
	border-radius: 7px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
}

.tool-header {
	color: var(--clr-green);
	margin-bottom: 1rem;
}

.row {
	display: flex;
}

.icon-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	transition: all 200ms;
	box-sizing: border-box;
}

.icon-container:hover {
	transform: scale(1.2);
}

.icon-container > img {
	width: 42px;
	height: 42px;
}

.icon-caption {
	color: white;
	margin-top: 8px;
	font-size: 0.8rem;
}

.js-image {
	width: 40px !important;
	height: 40px !important;
}

@media only screen and (max-width: 1500px) {
	.about-content > p {
		max-width: 60%;
	}
}

@media only screen and (max-width: 920px) {
	.about-header {
		font-size: 2rem;
		padding-bottom: 0.3rem;
		border-bottom-width: 3px;
	}

	.tech-header {
		font-size: 2rem;
		border-bottom-width: 3px;
	}

	.tool-container {
		flex-direction: column;
		height: auto;
	}

	.tool {
		min-width: 20%;
		margin-top: 1rem;
	}

	.about-content > p {
		max-width: 80%;
		font-size: 1rem;
	}
}

@media only screen and (max-width: 700px) {
	.about-content > p {
		font-size: 0.8rem;
	}

	.about-header {
		font-size: 1.5rem;
		padding-bottom: 0.3rem;
	}

	.tech-header {
		font-size: 1.5rem;
		padding-bottom: 0.3rem;
	}

	.tool-header {
		font-size: 1rem;
	}

	.wave-container svg {
		height: 100px;
	}
}

@media only screen and (max-width: 500px) {
	.about-header {
		padding-top: 5rem;
	}

	.wave-container svg {
		height: 50px;
	}
}
