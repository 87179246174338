.projects-page {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	overflow-x: hidden;
}

.wave-container2 {
	width: 100%;
	overflow: hidden;
	line-height: 0;
	transform: rotate(180deg);
}

.wave-container2 svg {
	position: relative;
	display: block;
	width: calc(128% + 1.3px);
	height: 120px;
}

.wave-container2 .shape-fill {
	fill: #2f2f2f;
}

/* .spacer {
	aspect-ratio: 675/900;
	width: 100%;
	height: auto;
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
}

.layer1 {
	background-image: url("../images/circle-scatter-haikei(2).svg");
} */

.projects-header {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-top: 1rem;
	font-weight: 600;
	font-size: 2.5rem;
	padding: 0.7rem;
	border-bottom: 5px solid var(--clr-red);
}

.projects-header-border-bottom {
	background-color: var(--clr-red);
	width: 9rem;
	height: 0.5rem;
	margin-top: 0.3rem;
	border-radius: 10px;
	margin-right: 1rem;
}

.projects-container {
	margin-top: 7rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 90%;
}

.project {
	display: flex;
	flex-shrink: 1;
	/* margin-left: 10rem; */
}

.project-video, 
.project3-image {
	width: 100%;
	border: 3rem solid var(--clr-green);
	border-left: 0;
	border-radius: 10px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.project2-image, .project4-image {
	width: 100%;
	border: 3rem solid var(--clr-green);
	border-right: 0;
	border-radius: 10px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.project-header {
	font-size: 2.3rem;
}

.project-description-header {
	font-size: 1.8rem;
}

.project-description {
	font-size: 1.2rem;
}

.project-info-container {
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 1rem;
	margin-left: 1rem;
}

.project-info-container > * {
	margin-bottom: 0.5rem;
}

.project2, .project3, .project4 {
	margin-top: 5rem;
}

.project4 {
	padding-bottom: 5rem;
}

.buttons-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1.3rem;
}

.button-github,
.button-live {
	padding: 0.3rem 1rem;
	font-size: 1rem;
	margin: 0 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	border: 3px solid var(--clr-red);
	color: var(--clr-red);
	transition: all 300ms ease;
	width: 9rem;
	font-weight: 800;
}

.button-github:hover,
.button-live:hover {
	cursor: pointer;
	background-color: var(--clr-red);
	color: white;
}

.project2-info-mobile, .project4-info-mobile {
	display: none;
}

@media only screen and (max-width: 1200px) {
	.project4-image,
	.project3-image,
	.project2-image,
	.project-video {
		border: 2rem solid var(--clr-green);
		border-radius: 10px;
	}

	.projects-container {
		align-items: center;
		justify-content: center;
		max-width: 90%;
	}

	.project {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0;
	}

	.project-info-container {
		align-items: center;
		margin-bottom: 3rem;
	}

	.project2-info-desktop, .project4-info-desktop {
		display: none;
	}

	.project2-info-mobile, .project4-info-mobile {
		display: flex;
	}
}

@media only screen and (max-width: 920px) {
	.projects-container {
		margin-top: 2rem;
		border-bottom-width: 3px;
	}

	.project-header {
		font-size: 2rem;
	}

	.project-description-header {
		font-size: 1.75rem;
	}

	.projects-header {
		font-size: 2rem;
	}

	.project-info-container > p {
		font-size: 1rem;
	}

	.button-github,
	.button-live {
		font-size: 1rem;
	}
}

@media only screen and (max-width: 700px) {
	.project-header {
		font-size: 1.5rem;
	}

	.project-description-header {
		font-size: 1.2rem;
	}

	.projects-header {
		font-size: 1.5rem;
	}

	.project-info-container > p {
		font-size: 0.8rem;
	}

	.button-github,
	.button-live {
		font-size: 0.8rem;
	}

	.wave-container2 svg {
		height: 100px;
	}

	.projects-container {
		max-width: 70%;
	}
}

@media only screen and (max-width: 500px) {
	.project4-image,
	.project3-image,
	.project2-image,
	.project-video {
		width: 85%;
		height: 85%;
		border-width: 10px;
	}

	.wave-container2 svg {
		height: 50px;
	}
}
